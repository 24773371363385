import React from "react"
import { useFilters, usePagination, useSortBy, useTable } from "react-table"
import { useExportData } from "react-table-plugins"
import { Button, Input } from "reactstrap"
import * as XLSX from "xlsx/xlsx.mjs"
import { DefaultColumnFilter } from "../../../filters/DefaultColumnFilter"
import { Filter } from "../../../filters/Filter"
import "./style.scss"

const TableContainer = ({
  columns,
  data,
  refreshTable,
  printAllSelectedBarcodes,
  isLoading,
}) => {
  const uTable = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 50 },
      getExportFileBlob,
    },
    useFilters,
    useSortBy,
    usePagination,
    useExportData
  )

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    exportData,
  } = uTable

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value))
  }

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "xlsx") {
      const header = columns.map((c) => c.exportValue)
      const compatibleData = data.map((row) => {
        const obj = {}
        header.forEach((col, index) => {
          obj[col] = row[index]
        })
        return obj
      })

      let wb = XLSX.utils.book_new()
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      })
      XLSX.utils.book_append_sheet(wb, ws1, "Orders List")
      XLSX.writeFile(wb, "OrdersExport.xlsx")
      return false
    }
    return false
  }

  const printWaitingForSupplierOrders = () => {
    let waitingOrders = data
      .filter((order) => {
        return order.orderStatus === "מחכה לאיסוף מספק"
      })
      .map((order) => {
        return `
        <tr>
        <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">${
          order.orderNumber
        }</td>
        <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">${
          order.packageNumber ? order.packageNumber : ""
        }</td>
        <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">${
          order.orderDate
        }</td>
        </tr>
      `
      })

    const htmlTable = `
    <table style="border-collapse: collapse; width: 100%; direction: rtl; font-size: 22px;">
    <thead>
      <tr>
        <th style="border: 1px solid #ddd; padding: 8px; text-align: right; background-color: #f2f2f2;">מספר הזמנה</th>
        <th style="border: 1px solid #ddd; padding: 8px; text-align: right;">מספר חבילה</th>
        <th style="border: 1px solid #ddd; padding: 8px; text-align: right;">תאריך</th>
      </tr>
    </thead>
    <tbody>
      ${waitingOrders.join("")}
    </tbody>
  </table>
    `
    const newWindow = window.open("orders", "_blank")
    newWindow.document.open()
    newWindow.document.write(htmlTable)
    newWindow.document.close()
    newWindow.print()
  }

  return (
    <div className="TableContainer">
      <div className="list-counter">
        {`נמצאו ${uTable.filteredRows.length} רשומות`}
      </div>
      <button
        className="export-button"
        onClick={() => {
          exportData("xlsx", false)
        }}
      >
        ייצוא לאקסל
      </button>
      <button
        className="export-button"
        onClick={() => {
          refreshTable()
        }}
      >
        רענן טבלה
      </button>
      <button
        className="export-button"
        onClick={() => {
          printWaitingForSupplierOrders()
        }}
      >
        הדפס הזמנות לאיסוף
      </button>

      {isLoading ? (
        <span className="barcod_loading">טוען...</span>
      ) : (
        <button
          className="export-button"
          onClick={() => {
            printAllSelectedBarcodes()
          }}
        >
          הדפס ברקודים
        </button>
      )}

      <div className="table-container">
        <div className="pagination">
          <div md={3}>
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>

          <div md={2} style={{ marginTop: 7 }}>
            דף{" "}
            <strong>
              {" "}
              {pageIndex + 1} מ-{pageOptions.length}{" "}
            </strong>
          </div>

          <div md={3}>
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>

            <div md={2}>
              <Input type="select" value={pageSize} onChange={onChangeInSelect}>
                {">"}
                {[50, 100, 150, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    הצג {pageSize}
                  </option>
                ))}
              </Input>
            </div>
          </div>
        </div>
        <table bordered="true" hover="true" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                    </span>
                    <div>{generateSortingIndicator(column)}</div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr
                  // onClick={() => onClick(row)}
                  key={row.id}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TableContainer
